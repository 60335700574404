/* .container {
  height: 100%;
  flex-direction: row;
}

.nav-icon {
  cursor: pointer;
  margin: 2px 0;
}

.nav-icon:hover {
  font-weight: bold;
  transform: scale(1.3);
}

.page-container {
  flex-grow: 1;
} */

/*  typography */
.text-center {
  text-align: center;
}

.text-grey {
  color: #4B607A;
}

.font-thin {
  font-weight: 400;
}

.text-danger{
  color: red;
}

.text-light {
  color: #D1DDEB;
}

/* 
.menu-items {
  padding: 0;
  text-align: center;
  color: white;
  width: 100%;
} 

.side-nav-bar {
  width: 60px;
  height: 100%;
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between; */
  /* align-items: center;  */
  /* horizontally center the child elements */
  /* padding-top: 10px;
  padding-bottom: 10px; */
  /* flex-shrink: 0;  */
  /* prevent the element from shrinking */
  /* min-width: 60px;  */
  /* set a minimum width */
/* } */

/* .child-a {
  align-self: flex-start;
}

.child-b {
  align-self: flex-end;
}

*/

.ReactModal__Content {
  height: fit-content;
  width: fit-content;
  margin: 10% auto 0 auto;
  padding: 20px;
  /* position: relative; */
  position: static !important;
}

.modal:focus-visible {
  outline: 0;
}

.ReactModal__Overlay {
  background: rgb(70,70,70,.7) !important;
}


.shadow {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

/* 

.mobile-nav-bar {
  display: none;
}

@media screen and (max-width: 450px) {
  .side-nav-bar {
    display: none;
  }

  .mobile-nav-bar {
    display: block;
    background-color: var(--secondary-color);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    height: 60px;
  }

  .mobile-nav-bar .icon-container {
    display: block;
    width: 60px;
    height: 60px;
    background-image: url("images/Search-Icon.png");
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  .mobile-nav-bar.open {
    width: 100%;
    height: 100%;
  }

  .mobile-nav-bar.open .icon-container {
    background-image: url("images/Search-Icon.png");
  }

  .mobile-nav-bar.open + .page-container {
    margin-left: 60px;
  }
}
*/

.flex {
  display: flex;
} 

/* margins */

/* .mr-3 {
  margin-right: 5px;
}

@media screen and (min-width: 451px) {
  .mobile-nav-bar {
    display: none;
  }
} */

/* for the collection select menus */
.menuVisible { z-index: 100; }
.dropdownMenu { z-index: 101; }
