.filterModal {
    width: 1000px;
    height: 650px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.modalCloseButton {
    position: absolute;
    top: -10px;
    right: 0px;
    color: lightgray;
}

.filterSection {
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    margin: 0 auto;
    box-sizing: border-box;
    width: 100%;
}

.expandButton {
    background: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    display: block;
    margin: 0 auto 10px auto;
    width: fit-content;
}

.dateSelectors {
    display: flex;
    justify-content: left;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    gap: 10px;
}

.dateLabel {
    display: flex;
    flex-direction: column;
    align-items: left;
    color: #555;
}

.dateInput {
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.filterCategory {
    border-bottom: 1px solid #eee;
    padding: 10px 0;
    text-align: left;
}

.filterHeader {
    cursor: pointer;
    font-size: 18px;
    color: #555;
    margin-bottom: 10px;
    display: inline-block;
    text-transform: capitalize;
}

.filterHeader:hover {
    color: #000;
}

.filterSelect {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: left;
}

.selectAll {
    background: none;
    color: #2E54DE;
    border: none;
    cursor: pointer;
    padding: 0px;
    padding-left: 10px;
}

.selectAll:hover {
    color: blue;
}

.filterList {
    cursor: pointer;
    display: flex;
    align-items: left;
}

.filterCheckbox {
    margin-right: 5px;
}

.applyFilters {
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    width: fit-content;
    margin: 20px auto 0 auto;
    display: block;
}

.inlineContainer {
    display: flex;
    align-items: left;
    width: 100%;
}

.inlineContainer span {
    margin-right: 10px;
    font-size: larger;
}

.inlineContainer2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.inlineContainer2 span {
    font-size: larger;
}

.filterList {
    display: flex;
    align-items: left;
    cursor: pointer;
    border-radius: 20px;
    padding: 5px 10px;
    margin: 5px;
    background-color: #E1EBF5;
    color: #859FB9;
    transition: background-color 0.3s;
    text-transform: capitalize;
}

.filterList.selected {
    background-color: #AEF5DD;
    color: #3D7F69;
}

.customCheckbox {
    user-select: none;
    margin: 5px 10px;
}

.resetButton {
    background: none;
    color: #2E54DE;
    border: none;
    cursor: pointer;
    padding: 0px;
    padding-left: 10px;
}

.resetButton:hover {
    color: blue;
}

.filterOptions {
    overflow-y: auto;
}