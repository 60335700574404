.authContainer {
  display: flex;
  border: 1px solid black;
}

.authLeft {
  flex: 1;
  background-color: #F0F4F9;
  width: 50%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  border-right: 1px solid #d6e1ed;
}

.authLeft::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1px;
  background-color: #d6e1ed;
}

.authRight {
  flex: 1;
  background-color: rgb(255, 255, 255);
  width: 50%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.authButton {
  width: 150px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #7981af;
  background-color: white;
  font-size: 18px;
  font-family: Arial, sans-serif;
}

.authButton:hover {
  background-color: #d6e1ed;
}

.authGreenText {
  color: var(--primary-color);
}

.authTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  margin-top: 25%;
  margin-left: 50px;
  margin-right: 50px;
}

.authTextContainer h1 {
  font-size: 35px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  margin: 0;
}

.authTextContainer h1 span {
  color: var(--primary-color);
}

.authTextGetAccess h2 {
  font-family: Arial, sans-serif;
  font-size: 22px;
  color: var(--secondary-color);
  margin: 0;
  margin-top: 70px;
  margin-bottom: 10px;
  font-weight: normal;
}

.authTextGetAccess p {
  font-family: Arial, sans-serif;
  font-size: 15px;
  color: #7981af;
  margin: 0;
}

.authInputContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

.authInputRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.authInput {
  height: 50px;
  border-radius: 5px;
  border: none;
  background-color: #ffffff;
  padding-left: 20px;
  font-size: 18px;
  font-family: Arial, sans-serif;
}

.authEmailInput,
.authPasswordInput {
  width: 520px;
  margin-bottom: 25px;
}

.authButtonWaitlist {
  width: 520px;
  height: 50px;
  border-radius: 10px;
  border: none;
  /*background-color: var(--primary-color);*/
  background-color: var(--primary-color);
  color: white;
  font-size: 18px;
  font-family: Arial, sans-serif;
  margin-left: 0;
}

.authButtonWaitlist:hover {
  background-color: var(--primary-color);
  cursor: pointer;
  font-weight: 500;
}

.authHowItWorksWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.authHowItWorks {
  text-align: left;
}

.authHowItWorksRow {
  display: flex;
  align-items: center;
}

.authHowItWorksText {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.authHowItWorksText h3 {
  margin-bottom: 0px;
  font-size: 22px;
}

.authHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.authHeaderInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.icon1 {
  background-image: url('../../images/create.png');
  background-size: 40px 40px;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.icon2 {
  background-image: url('../../images/collect.png');
  background-size: 40px 40px;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.icon3 {
  background-image: url('../../images/share.png');
  background-size: 40px 40px;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.logoContainer {
  width: 150px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.authEmailRowWaitlist {
  margin-top: 25px;
}

.authErrorMessage {
  color: red;
  font-size: 12px;
  font-family: Arial, sans-serif;
  font-weight: normal;
  margin-bottom: 10px;
  text-align: left;
}

.authConfirmationCodeInput,
.authNewPasswordInput,
.authConfirmNewPasswordInput {
  width: 520px;
  margin-bottom: 25px;
}

.authButtonResend {
  margin-left: 10px;
}

.authButtonChangePassword {
  margin-top: 10px;
}

.authButtonResend,
.authButtonChangePassword {
  width: 220px;
}

.authErrorMessage {
  color: red;
  font-size: 12px;
  font-family: Arial, sans-serif;
  font-weight: normal;
  margin-bottom: 10px;
  text-align: left;
}

.confButtonWaitlist {
  width: 260px;
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: var(--primary-color);
  color: white;
  font-size: 18px;
  font-family: Arial, sans-serif;
  margin-left: 0;
}

.confButtonWaitlist:hover {
  background-color: var(--primary-color);
  cursor: pointer;
}

.forgotPassword {
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #585858;
  text-decoration: none;
  margin-top: 15px;
}

@media (max-width: 768px) {
  .authContainer {
    flex-direction: column;
  }

  .authTextContainer {
    padding-top: 40px;
    align-items: center;
    text-align: center;
  }
  
  .authTextContainer h1 {
    font-size: 24px;
  }

  .authLeft {
    top: 60px;
    position: absolute;
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
  }

  .authLeft::after {
    display: none;
  }

  .authTextContainer {
    margin-top: 0;
  }

  .authInputRow {
    flex-wrap: wrap;
  }

  .authEmailInput {
    width: 100%;
  }

  .authEmailRow {
    margin-bottom: 10px;
  }

  .authEmailRowWaitlist {
    margin-top: 0px;
  }

  .authButtonWaitlist {
    width: 100%;
  }

  .authHowItWorks {
    width: 90%;
  }

  .authHeader {
    padding: 0 5px;
    position: fixed;
    top: 0;
    left: 0;
    height: 60px;
    background-color: #F0F4F9;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  
  .authHeaderInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 20px;
  }

  .authRight {
    display: none;
  }

  .authInput {
    margin: 0 5px;
  }

  .authInputContainer {
    align-items: center;
  }

  .authInputRow {
    justify-content: center;
    margin-bottom: 10px;
  }

  .authInputRowWaitlist {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; 
  }

  .authInput, .authEmailInput, .authButtonWaitlist {
    width: 350px;
    box-sizing: border-box;
  }

  .authInput, .authEmailInput {
    margin-bottom: 20px;
  }

  .authButtonWaitlist {
    margin: 10px auto 0;
  }

  .authButton {
    font-size: 14px;
    padding: 8px 12px;
    outline: none;
    border: none;
    background: none;
  }
  
  .authButton:hover,
  .authButton:active,
  .authButton:focus {
    background: none;
    border: none;
    outline: none;
  }
  
  .authButtonRow {
    margin-top: 5px;
  }

  .authGreenText {
    font-size: 24px;
  }

  .authInputContainerWaitlist .authInputRow {
    margin-bottom: 10px;
  }
}