@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;*/
  font-family: Arial, sans-serif;
  /*font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root,
body,
html {
  height: 100%;
}

* {
  box-sizing: border-box;
}

/* Global styles for Markdown content */
.markdown {
  /* Typography */
  font-family: "Helvetica", "Arial", sans-serif;
  line-height: 1.6;
  color: #333;

  /* Headings */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: bold;
    line-height: 1.25;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.25em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 0.875em;
  }

  h6 {
    font-size: 0.85em;
    color: #6a737d;
  }

  /* Paragraph */
  p {
    margin-top: 0;
    margin-bottom: 10px;
  }

  /* Links */
  a {
    color: #0366d6;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  /* Lists */
  ul,
  ol {
    padding-left: 20px;
    margin-top: 0;
    margin-bottom: 10px;
  }

  li {
    margin-top: 5px;
  }

  li>p {
    margin-top: 5px;
  }

  li+li {
    margin-top: 5px;
  }

  /* Code */
  code {
    font-family: "Courier New", Courier, monospace;
    background-color: #5A5A5A;
    padding: 3px 5px;
    border-radius: 3px;
  }

  pre {
    background-color: #5A5A5A;
    border-radius: 3px;
    padding: 10px;
    overflow-x: auto;
  }

  pre code {
    background: transparent;
    padding: 0;
    border-radius: 0;
  }

  /* Blockquotes */
  blockquote {
    margin-top: 0;
    margin-bottom: 10px;
    margin-left: 0;
    padding-left: 15px;
    border-left: 4px solid #ccc;
    color: #6a737d;
    page-break-inside: avoid;
  }

  blockquote> :first-child {
    margin-top: 0;
  }

  blockquote> :last-child {
    margin-bottom: 0;
  }

  /* Tables */
  table {
    border-collapse: collapse;
    margin-top: 0;
    margin-bottom: 16px;
    display: block;
    width: max-content;
    overflow: auto;
  }

  table th,
  table td {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
  }

  table tr {
    background-color: #fff;
    border-top: 1px solid #c6cbd1;
  }

  table tr:nth-child(2n) {
    background-color: #f6f8fa;
  }

  table th {
    font-weight: bold;
    background-color: #f6f8fa;
  }

  /* Images */
  img {
    max-width: 100%;
    box-sizing: content-box;
    background-color: #fff;
  }

  /* Horizontal Rule */
  hr {
    background-color: #e1e4e8;
    border: 0;
    height: 0.25em;
    padding: 0;
    margin: 24px 0;
  }
}

/*.markdown > * {
  all: revert;
  margin-top: 0px;
}*/

button {
  font-size: 16px;
  color: #646464;
  background-color: #e7e7e7;
  border-radius: 5px;
  width: 120px;
  height: 40px;
}

code {
 background-color: rgb(23 23 23);
 color:white
}

pre {
  all: revert;
  background: rgb(64 64 64);
  border-radius: .5rem;
  color: white;
  margin: 1.5rem 0;
  padding: .75rem 1rem;
}

pre code {
  all: revert;
  background: none;
  color: inherit;
  font-size: .8rem;
  padding: 0;
}

.app-gradient-background {
  background: linear-gradient(112deg,
      var(--primary-color) -30%,
      #ffffff 25%,
      #ffffff 75%,
      var(--secondary-color) 110%);
}

.chart-title-font {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 900;
  opacity: 1;
  color: #373d3f;
}

/* date-range-picker stylings */
.rdrStaticRange {
  width: 100%;
}

.rdrInputRangeInput {
  padding: 0;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
}

.rdrInputRangeInput:focus {
  border-color: var(--primary-color) !important;
}

.rdrInputRangeInput:hover {
  border-color: var(--primary-color) !important;
}

.rdrDateDisplayItemActive input {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
}

.rdrMonthPicker select {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
}

.rdrYearPicker select {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
}

.rdrDateRangePickerWrapper {
  border-bottom: 1px solid #eff2f7;
}

.rdrDateDisplayItem {
  pointer-events: none !important;
}

.rdrDateDisplayItemActive {
  border: none !important;
}

.rdrInputRange:nth-child(2) {
  display: none;
}

/* revert some conflicting tailwind styles */
.revert-some-tailwind-styles {
  ul {
    all: revert;
  }

  ol {
    all: revert;
  }

  /* // allows table borders to show up */
  td {
    /* border: solid 1px; */
  }

  a:link {
    all: revert;
  }

  a:link:hover {
    /* cursor: pointer; */
  }

  button {
    width: auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote {
    all: revert;
  }
}

/* markdown editor styles */
.markdown-text-editor {
  ul {
    all: revert;
  }

  ol {
    all: revert;
  }

  a {
    color: var(--accentText);
    font-weight: 500;
  }

  a:link {
    text-decoration: underline;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    all: revert;
  }
}

.mdxeditor {
  height: 100%;
  border: 1px solid #f0f0f3;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.readonly .mdxeditor {
  min-height: 100%;
  height: auto
}

.mdxeditor:focus-within {
  border: 1px solid var(--primary-color);
  border-radius: 6px;
}

.mdxeditor-toolbar {
  display: flex;
  flex-wrap: wrap;
}

._toolbarRoot_1qea1_147 {
  overflow-x: visible !important;
}

.mdxeditor-diff-source-wrapper {
  flex-grow: 1;
  display: flex !important;
  flex-direction: column;
  border: 1px solid white;
  border-radius: 6px;
}

.rich-text .mdxeditor-rich-text-editor {
  flex-grow: 1;
  display: flex !important;
  flex-direction: column;
}

.rich-text .mdxeditor-root-contenteditable {
  display: flex !important;
  flex-direction: column;
  flex-grow: 1;
}

.rich-text ._contentEditable_1qea1_356 {
  flex-grow: 1;
}

.prose :where(p):not(:where([class~=not-prose] *)) {
  margin-bottom: 1.25em
}

.prose :where(blockquote):not(:where([class~=not-prose] *)) {
  font-weight: 500;
  font-style: italic;
  color: #111827;
  border-left-width: .25rem;
  border-left-color: #e5e7eb;
  quotes: "\201C" "\201D" "\2018" "\2019";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
}

._tableEditor_1qea1_676>tbody>tr>th:not(._toolCell_1qea1_687) {
  border: 1px solid var(--baseBgActive);
  padding: var(--spacing-1) var(--spacing-2);
  white-space: normal;
}

:is(._tableEditor_1qea1_676>tbody>tr>td:not(._toolCell_1qea1_687), ._tableEditor_1qea1_676>tbody>tr>th:not(._toolCell_1qea1_687))>div>p {
  margin: 0 !important;
}

.prose li[role=checkbox]:before {
  transform: translate(6px, 6px);
}

.prose li[role=checkbox] {
  text-indent: 1.2rem;
}

._linkDialogPopoverContent_1qea1_564>button:first-of-type {
  display: none;
}

._textInput_1qea1_1151:focus-within {
  --tw-ring-color: var(--primary-color);
  border: none;
}

/* scrollbars */

.light-scroll-bar {
  scrollbar-color: #ececec transparent;
}

.dark-scroll-bar {
  scrollbar-color: #d1d5db transparent;
}

.allow-scrolling-without-scrollbar {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.allow-scrolling-without-scrollbar::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

/* tiptap editor styles */
.tiptap-editor {
  ul[data-type=taskList] li {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  ul[data-type=taskList] li[data-checked="true"] {
    text-decoration: line-through;
  }

  ul[data-type=taskList] li div p {
    min-width: 10px;
  }

  [type='checkbox'] {
    outline-color: var(--primary-color);
    --tw-ring-color: var(--primary-color);
    border: 1px solid black
  }

  [type='checkbox']:checked {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
  }

  .ProseMirror-selectednode div div {
    border: 1px solid #e5e7eb !important;
    background-color: white !important;
  }

  .ProseMirror-selectednode div img {
    width: 99% !important;
  }

  .ProseMirror-selectednode div div img:hover {
    opacity: 0.75 !important;
  }

  .ProseMirror-selectednode div div img:first-child {
    content: url(../src/images/left-align.png) !important;
  }

  .ProseMirror-selectednode div div img:nth-child(2) {
    content: url(../src/images/center-align.png) !important;
  }

  .ProseMirror-selectednode div div img:nth-child(3) {
    content: url(../src/images/right-align.png) !important;
  }

  .uneditable-img div {
    border: none !important;
    cursor: default !important;
  }

  .uneditable-img div img {
    cursor: default !important;
    width: 100% !important;
  }

  .uneditable-img div:nth-child(2) {
    display: none !important;
  }

  .uneditable-img div:nth-child(3) {
    display: none !important;
  }

  .uneditable-img div:nth-child(4) {
    display: none !important;
  }

  .uneditable-img div:nth-child(5) {
    display: none !important;
  }

  .uneditable-img div:nth-child(6) {
    display: none !important;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    object-fit: contain;
  }
}

.tiptap p.is-empty::before {
  color: #94a3b8;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
  font-weight: 400;
  font-size: large;
}

/* Temporary hide placeholder text for center and right aligned texts because
 when the text is center or right aligned some alignemnt issues occur in placeholder*/
.tiptap p.is-empty[style*="text-align: center"]::before {
  color: transparent;
}

.tiptap p.is-empty[style*="text-align: right"]::before {
  color: transparent;
}